<template>
  <v-navigation-drawer
    :value="isFilterProductSidebarActive"
    temporary
    touchless
    floating
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    :class="$vuetify.breakpoint.smAndUp ? 'rounded-tl-xl rounded-bl-xl' : null"
    @input="val => $emit('update:is-product-filter-sidebar-active', val)"
  >
    <v-sheet :color="isDark ? '#363351' : '#fafafa'" class="pa-3 d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <v-avatar size="48" color="secondary">
          <v-icon size="24" class="white--text">
            {{ icons.mdiFilter }}
          </v-icon>
        </v-avatar>
        <div class="text-h6 font-weight-medium ml-3">Filtreleme</div>
      </div>
      <v-btn icon x-small @click="$emit('update:is-product-filter-sidebar-active', false)">
        <v-icon> {{ icons.mdiClose }}</v-icon>
      </v-btn>
    </v-sheet>

    <v-container>
      <!-- Tarih Seçici ve Döviz Bileşenleri -->
      <v-row no-gutters>
        <v-col cols="12">
          <v-menu
            v-model="startMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filter.startDate"
                label="Başlangıç Tarihi"
                hide-details=""
                readonly
                filled
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filter.startDate"
              :max="maxDate"
              no-title
              @input="startMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <v-menu
            v-model="endMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filter.endDate"
                label="Bitiş Tarihi"
                hide-details=""
                readonly
                filled
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter.endDate" :max="maxDate" no-title @input="endMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="filter.bankaKodu"
            label="Banka"
            style="width: 100%"
            :items="bankaData"
            hide-details
            class="rounded-lg"
            filled
            item-text="text"
            item-value="value"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="filter.dovizKodu"
            label="Döviz"
            style="width: 100%"
            :items="dovizData"
            hide-details
            class="rounded-lg"
            filled
            item-text="text"
            item-value="value"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <!-- Butonlar -->
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn x-large block rounded class="text-capitalize filter-btn" color="secondary" @click="filtering">
            Filtrele
          </v-btn>
        </v-col>
        <v-col v-if="isFilter" cols="12" class="d-flex justify-center">
          <v-btn x-large block rounded plain @click="filterClear" class="text-capitalize clear-btn"> Temizle </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import axios from '@axios'
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiClose, mdiFilter, mdiFilterOff } from '@mdi/js'
import { onMounted, onUnmounted, ref, watch } from '@vue/composition-api'
import Vue from 'vue'
export default {
  model: {
    prop: 'isFilterProductSidebarActive',
    event: 'update:is-product-filter-sidebar-active',
  },
  props: {
    isFilterProductSidebarActive: {
      type: Boolean,
      required: true,
    },
    sendData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      bankaData: [],
      dovizData: [],
    }
  },
  setup(props, { emit }) {
    onMounted(() => {
      window.addEventListener('keydown', keyDownHandler)
    })
    onUnmounted(() => {
      window.removeEventListener('keydown', keyDownHandler)
    })
    const keyDownHandler = event => {
      if (event.code === 'Escape') {
        emit('update:is-product-filter-sidebar-active', false)
      }
    }

    const maxDate = new Date().toISOString().slice(0, 10)
    const { isDark } = useAppConfig()
    const startDate = ref(null)
    const endDate = ref(null)
    const doviz = ref(null)
    const banka = ref(null)
    const startMenu = ref(false)
    const endMenu = ref(false)

    const filter = ref({
      id: '',
      siparisDurum: '',
      odemeDurum: '',
      startDate: '',
      endDate: '',
      minPrice: '',
      maxPrice: '',
      banka: '',
      doviz: '',
    })
    const defaultFilter = ref({
      id: '',
      siparisDurum: '',
      odemeDurum: '',
      startDate: '',
      endDate: '',
      minPrice: '',
      maxPrice: '',
      banka: '',
      doviz: '',
    })

    const search = ref()
    const isLoading = ref(false)
    const items = ref([])
    const isFilter = ref(0)
    const filteringStatus = ref(false)

    const filtering = () => {
      filteringStatus.value = true
      emit('filter-data', filter.value)
      emit('update:is-product-filter-sidebar-active', false)
    }
    const filterClear = () => {
      emit('filter-data', {})
      emit('update:is-product-filter-sidebar-active', false)
      filter.value = {
        id: '',
        aktif: '',
        baslik: '',
        urun_kodu: '',
        plant: '',
        startDate: '',
        endDate: '',
        banka: '',
        doviz: '',
      }
      Vue.swal({
        title: 'Temizlendi',
        text: 'Filtre Temizlendi',
        icon: 'success',
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      })
    }

    watch(
      filter,
      item => {
        let i = 0
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(item)) {
          if (value) {
            i += 1
          }
        }
        isFilter.value = i
      },
      { deep: true },
    )

    watch(
      () => props.isFilterProductSidebarActive,
      val => {
        if (filteringStatus.value) {
        }
        if (!val) {
          filteringStatus.value = false
          if (Object.keys(props.sendData).length > 0) {
            filter.value = { ...props.sendData }
          } else {
            filter.value = { ...defaultFilter.value }
          }
        }
      },
    )

    return {
      items,
      isFilter,
      isLoading,
      isDark,
      filter,
      filtering,
      filterClear,
      search,
      startDate,
      maxDate,
      endDate,
      startMenu,
      endMenu,
      filteringStatus,
      banka,
      doviz,
      icons: {
        mdiFilter,
        mdiFilterOff,
        mdiClose,
      },
    }
  },

  methods: {
    fetchBankalar() {
      const params = new URLSearchParams()
      params.append('method', 'getBankList')
      params.append('web_api', 1)
      axios.post('', params).then(response => {
        if (response.error_code === 99) {
          Vue.swal({
            title: 'Oturum Sonlandı!',
            html: 'Geçersiz Token',
            icon: 'error',
            background: '#FF4C51',
            timerProgressBar: true,
            showConfirmButton: false,
          })
          router.push('/login')

          return
        }
        if (response.error === true) {
          return
        }

        this.bankaData.push({
          value: 0,
          text: 'Hepsi',
        })
        for (const [key, value] of Object.entries(response.data.response.bankalar)) {
          this.bankaData.push({
            value: value.banka,
            text: value.banka,
          })
        }
      })
    },
    fetchCurrenciesData() {
      const params = new URLSearchParams()
      params.append('method', 'getCurrencies')
      params.append('web_api', 1)
      axios
        .post('', params)
        .then(response => {
          if (response.data.error) {
            loadingSelectText.value = response.data.msg
            loadingSelectStatus.value = 'error'

            return
          }
          if (response.data.error_code === 99) {
            Vue.swal({
              title: 'Oturum Sonlandı!',
              html: 'Geçersiz Token',
              icon: 'error',
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
            router.push('/login')

            return
          }

          this.dovizData.push({
            value: 0,
            text: 'Hepsi',
          })
          for (const [key, value] of Object.entries(response.data.response)) {
            this.dovizData.push({
              value: value.doviz,
              text: value.doviz,
            })
          }
        })
        .catch(e => {
          loadingSelectText.value = 'Bir hata oldu tekrar deneyin'
          loadingSelectStatus.value = 'error'
        })
    },
  },

  created() {
    this.fetchBankalar()
    this.fetchCurrenciesData()
  },
}
</script>

<style scoped>
.filter-btn {
  /* Varsayılan margin */
  margin-bottom: 0rem; /* Genel margin değeri */
  position: relative; /* Butonu diğer içeriklerin üstünde tutar */
  z-index: 10; /* Yüksek z-index değeri ile diğer elemanların üstünde görünür */
}

.clear-btn {
  position: relative; /* Butonu diğer içeriklerin üstünde tutar */
  z-index: 10; /* Yüksek z-index değeri ile diğer elemanların üstünde görünür */

  background-color: #f44336; /* İstediğiniz renk kodunu buraya ekleyin */
  color: white; /* Yazı rengini beyaz yapar */
  border: none; /* Butonun kenar çizgilerini kaldırır */
}

/* Ekstra stil ayarları */
@media (max-width: 600px) {
  .filter-btn,
  .clear-btn {
    width: 100%; /* Butonları mobil ekranlarda tam genişlikte yapar */
    margin-bottom: 0rem; /* Mobilde daha fazla boşluk */
  }
}

.clear-btn:hover {
  background-color: #d32f2f; /* Hover durumunda arka plan rengini değiştirir */
}
</style>
